import { Box, Icon, styled, Theme, useMediaQuery } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles, useTheme } from "@material-ui/styles";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React, { FunctionComponent } from "react";
import { CategoryModel } from "../../types";
import HorizontalListItem from "./HorizontalListItem";

const useStyle = makeStyles<Theme>((theme: Theme) => ({
  navButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: 50,
    height: 50,
    background: "transparent",
    color: theme.palette.secondary.main || "#fff",
    border: 0,
    outline: "none",
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.light, 0.8) || "#00000085"
    }
  },
  slider: {
    padding: "10px 0 10px 9px",
    margin: "0 0 0 -10px"
  },
  slide: {
    // margin: -1
  },
  innerSlide: {
    padding: 10
  }
}));

const CategoryTitle = styled("h6")({
  fontSize: "1.7rem",
  color: "#232020d1",
  paddingLeft: 10
});

const HorizontalList: FunctionComponent<{
  category: CategoryModel;
}> = ({ category }) => {
  const classes = useStyle();
  const theme = useTheme<Theme>();
  let visibleSlides = 8;
  const isXSmall = useMediaQuery(theme.breakpoints.between("xs", "xs"));
  const isSmall = useMediaQuery(theme.breakpoints.between("sm", "sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "md"));
  const isLarge = useMediaQuery(theme.breakpoints.between("lg", "lg"));
  const isXLarge = useMediaQuery(theme.breakpoints.between("xl", "xl"));

  if (isXSmall) {
    visibleSlides = 2;
  } else if (isSmall) {
    visibleSlides = 4;
  } else if (isMedium) {
    visibleSlides = 6;
  } else if (isLarge) {
    visibleSlides = 8;
  } else if (isXLarge) {
    visibleSlides = 12;
  }

  return (
    <Box my={2}>
      <CategoryTitle>{category.title}</CategoryTitle>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={category.items.length}
        visibleSlides={visibleSlides}
        infinite
        dragEnabled={false}
      >
        <div style={{ position: "relative" }}>
          <Slider className={classes.slider}>
            {category.items.map((catItem, idx) => (
              <Slide
                key={catItem.id}
                className={classes.slide}
                innerClassName={classes.innerSlide}
                index={idx}
              >
                <HorizontalListItem item={catItem} />
              </Slide>
            ))}
          </Slider>
          <div
            style={{ position: "absolute", top: "calc(50% - 25px)", left: 10 }}
          >
            <ButtonBack className={classes.navButton}>
              <Icon component={ChevronLeftIcon} />
            </ButtonBack>
          </div>
          <div
            style={{ position: "absolute", top: "calc(50% - 25px)", right: 10 }}
          >
            <ButtonNext className={classes.navButton}>
              <Icon component={ChevronRightIcon} />
            </ButtonNext>
          </div>
        </div>
      </CarouselProvider>
    </Box>
  );
};

export default HorizontalList;
