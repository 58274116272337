import React, { FunctionComponent } from "react";
import { OfferModel } from "../../types";
import { Image } from "pure-react-carousel";
import { styled } from "@material-ui/core";

const ItemWrapper = styled("div")({
  height: "100%",
  width: "100%",
  backgroundColor: "#212121",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    backgroundColor: "#00000010",

    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
});
const ItemTitle = styled("div")({
  left: 0,
  right: 0,
  bottom: 0,
  paddingTop: 15,
  paddingBottom: 15,
  backgroundColor: "#00000050",
  wordWrap: "break-word",
  width: "100%",
  position: "absolute",
  textAlign: "center",
  color: "#f4f4f4",
  zIndex: 100,
  fontSize: "1.8rem",
  pointerEvents: "none"
});

const HorizontalListItem: FunctionComponent<{ item: OfferModel }> = ({
  item
}) => {
  return (
    <ItemWrapper>
      <ItemTitle>{item.title}</ItemTitle>
      {item.image ? (
        <Image hasMasterSpinner={false} style={{}} src={item.image}></Image>
      ) : null}
    </ItemWrapper>
  );
};
export default HorizontalListItem;
