import { styled } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import MainContainer from "./layout/main/MainContainer";
import Navbar from "./layout/nav/Navbar";
import StyledApp from "./StyledApp";
import { CategoryModel, OfferModel, TabModel } from "./types";

const Wrapper = styled("div")({
  minHeight: "100vh",
  maxWidth: "100vw"
});

const App: FunctionComponent<{}> = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabs] = useState<Array<TabModel>>([
    {
      id: 1,
      title: "First Tab"
    },
    {
      id: 2,
      title: "Second Tab"
    },
    {
      id: 3,
      title: "Third Tab"
    },
    {
      id: 4,
      title: "Forth Tab"
    },
    {
      id: 5,
      title: "Fifth Tab"
    },
    {
      id: 6,
      title: "Sixth Tab"
    }
  ]);

  const [categories] = useState<CategoryModel[]>([
    ...Array(5)
      .fill(0)
      .map((_, catIdx) => ({
        id: catIdx + 1,
        title: `Category ${catIdx + 1}`,
        items: [
          ...Array(12)
            .fill(0)
            .map<OfferModel>((_, itemIdx) => ({
              id: itemIdx + 1,
              title: `Item ${itemIdx + 1}`,
              image: `https://source.unsplash.com/random/${190 +
                ((itemIdx + catIdx) % 3)}x${252 - ((itemIdx + catIdx) % 3)}`
            }))
        ]
      }))
  ]);

  return (
    <Wrapper>
      <Navbar tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <MainContainer categories={categories} />
    </Wrapper>
  );
};
export default StyledApp(App);
