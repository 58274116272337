import { Box, Container } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { CategoryModel } from "../../types";
import HorizontalList from "../list/HorizontalList";
import { styled } from "@material-ui/styles";

const SeparatorWrapper = styled("div")({
  width: "100%",
  padding: "20px 10px;"
});
const Separator = styled("div")({
  width: "100%",
  height: 1,
  backgroundColor: "#0000001a"
});

const MainContainer: FunctionComponent<{ categories: CategoryModel[] }> = ({
  categories
}) => {
  return (
    <Container maxWidth={false} component={"main"}>
      <Box my={10}>
        {categories.map(category => (
          <>
            <HorizontalList key={category.id} category={category} />
            <SeparatorWrapper>
              <Separator />
            </SeparatorWrapper>
          </>
        ))}
      </Box>
    </Container>
  );
};

export default MainContainer;
