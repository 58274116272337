import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import React, { FunctionComponent } from "react";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green
  }
});

const StyledApp = (C: FunctionComponent) => (props: any) => {
  return (
    <ThemeProvider theme={theme}>
      <C {...props} />
    </ThemeProvider>
  );
};

export default StyledApp;
