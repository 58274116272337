import {
  AppBar,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Slide,
  useScrollTrigger
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { TabModel } from "../../types";

const useStyles = makeStyles({
  tabsRoot: {
    margin: "0 auto"
  }
});

export type NavbarProps = {
  tabs: Array<TabModel>;
  tabIndex: number;
  setTabIndex: (index: number) => void;
};

const Navbar: FunctionComponent<NavbarProps> = ({
  tabs,
  tabIndex,
  setTabIndex
}) => {
  const classes = useStyles();
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar position="fixed">
        <Toolbar>
          <Tabs
            classes={{ root: classes.tabsRoot }}
            value={tabIndex}
            onChange={(e, v) => setTabIndex(v)}
            indicatorColor={"secondary"}
            textColor={"secondary"}
            variant={"scrollable"}
            scrollButtons={"on"}
          >
            {tabs.map((tab, idx) => {
              const style: React.CSSProperties = { fontSize: "1.2rem" };
              if (idx !== tabIndex) {
                style.color = "#fff";
              }
              return <Tab key={tab.id} style={style} label={tab.title} />;
            })}
          </Tabs>
        </Toolbar>
      </AppBar>
    </Slide>
  );
};
export default Navbar;
